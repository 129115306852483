const inchesInFoot = 12
const centimeterToInch = 0.39370

export const profileHelper = {
  data() {
    return {
      heightAux: { cm: 0, ft: 0, inch: 0 },
      feetOptions: [...Array(8).keys()],
      inchOptions: [...Array(12).keys()],
      genderOptions: [
        {label: this.__('interface.male'), value: 'male'},
        {label: this.__('interface.female'), value: 'female'},
        {label: this.__('interface.not-to-say'), value: 'not-to-say'}
      ],
      heightOptions: [
        {label: this.__('interface.centimeters'), value: 'cm'},
        {label: this.__('interface.feet'), value: 'inch'}
      ],
      weightOptions: [
        {label: this.__('interface.kilograms'), value: 'kg'},
        {label: this.__('interface.pounds'), value: 'lbs'}
      ],
    }
  },
  methods: {
    calcCurrentInches () {
      return this.heightAux.ft * inchesInFoot + this.heightAux.inch
    },
    convertFromCmToInch (centimeters) {
      const inches = Math.round(centimeters * centimeterToInch)
      this.heightAux = {
        cm: centimeters,
        ft: Math.floor(inches / inchesInFoot),
        inch: inches % inchesInFoot
      }
    },
    convertFromInchToCm (inches) {
      const centimeters = Math.round(inches / centimeterToInch)
      this.heightAux = {
        cm: centimeters,
        ft: Math.floor(inches / inchesInFoot),
        inch: inches % inchesInFoot
      }
    },
    initHeightConvert (heightUnit) {
      if (heightUnit === 'cm') {
        this.convertFromCmToInch(this.form.height)
      } else {
        this.convertFromInchToCm(this.form.height)
      }
    },
    cmChanged (e) {
      this.heightAux.cm = e.target.value;
      this.convertFromCmToInch(this.heightAux.cm)
    },
    feetChanged(e) {
      this.heightAux.ft = e.target.value;
      this.convertFromInchToCm(this.calcCurrentInches())
    },
    inchChanged (e) {
      this.heightAux.inch = e.target.value;
      this.convertFromInchToCm(this.calcCurrentInches())
    },
    calcHeightForSave(heightUnit) {
      return heightUnit === 'cm' ? this.heightAux.cm : this.calcCurrentInches()
    }
  }
}