import {toastController} from "@ionic/vue";
import i18n from '@/i18n'

export const showMessages = async function (messages) {
  if(!messages || messages.length === 0)
    return;

  const firstMessage = messages[0];
  let content = "";

  if(firstMessage.content) {
    content = firstMessage.content
  } else if (firstMessage.type === 'error') {
    content = 'message.something-went-wrong'
  } else {
    return
  }

  const toast = await toastController.create({
    message: i18n(content),
    duration: 2000
  })
  await toast.present();
}

export default { showMessages }
