// temp function, this should be also handled in 'showMessages' or by error on inputs

import {toastController} from "@ionic/vue";
import i18n from '@/i18n'

export const showValidationMessages = async function (errors) {
  if(!errors)
    return;

  const firstMessage = Object.values(errors)[0][0];

  const toast = await toastController.create({
    message: firstMessage ? firstMessage: i18n('message.something-went-wrong'),
    duration: 2000
  })
  await toast.present();
}

export default { showValidationMessages }



