<template>
  <ion-page :cache-view="false">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'MyProfile'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.personal-information')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" v-if="!loadingState">
      <ion-item>
        <ion-label position="floating">{{ __('interface.first-name') }}</ion-label>
        <ion-input v-model="form.first_name" v-on:ionInput="form.first_name = $event.target.value"/>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{ __('interface.last-name') }}</ion-label>
        <ion-input v-model="form.last_name" v-on:ionInput="form.last_name = $event.target.value"/>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{ __('interface.date-of-birth') }}</ion-label>
        <ion-datetime display-format="DD/MM/YYYY" picker-format="DD MMMM YYYY" v-model="form.date_of_birth"
                      @ionBlur="form.date_of_birth = $event.target.value"/>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{ __('interface.gender') }}</ion-label>
        <ion-select @ionBlur="form.sex = $event.target.value" v-model="form.sex" :ok-text="__('interface.ok')"
                    :cancel-text="__('interface.cancel')">
          <ion-select-option v-for="option in genderOptions" :key="option.value" :value="option.value">
            {{ option.label }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-row>
        <ion-col size="8">
          <ion-item v-if="form.height_unit === 'cm'">
            <ion-label position="floating">{{ __('interface.height') }}</ion-label>
            <ion-input v-model="heightAux.cm" @ionInput="cmChanged"/>
          </ion-item>
          <ion-item v-if="form.height_unit === 'inch'">
            <ion-label position="floating">{{ __('interface.height') }}</ion-label>
            <ion-select @ionBlur="feetChanged" v-model="heightAux.ft"
                        :ok-text="__('interface.ok')" :cancel-text="__('interface.cancel')">
              <ion-select-option v-for="(option, key) in feetOptions" :key="key" :value="option">
                {{ option }}
              </ion-select-option>
            </ion-select>          
            </ion-item>
          <ion-item v-if="form.height_unit === 'inch'">
            <ion-label position="floating">&nbsp;</ion-label>
            <ion-select @ionBlur="inchChanged" v-model="heightAux.inch"
                        :ok-text="__('interface.ok')" :cancel-text="__('interface.cancel')">
              <ion-select-option v-for="(option, key) in inchOptions" :key="key" :value="option">
                {{ option }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col size="4">
          <ion-item>
            <ion-label position="floating">{{ __('interface.unit') }}</ion-label>
            <ion-select @ionBlur="form.height_unit = $event.target.value" v-model="form.height_unit"
                        :ok-text="__('interface.ok')" :cancel-text="__('interface.cancel')">
              <ion-select-option v-for="(option, key) in heightOptions" :key="key" :value="option.value">
                {{ option.label }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item v-if="form.height_unit === 'inch'">
            <ion-label position="floating">&nbsp;</ion-label>
            <ion-input disabled :value="__('interface.inches')" />
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="8">
          <ion-item>
            <ion-label position="floating">{{ __('interface.weight') }}</ion-label>
            <ion-input v-model="form.weight" v-on:ionInput="form.weight = $event.target.value"/>
          </ion-item>
        </ion-col>
        <ion-col size="4">
          <ion-item>
            <ion-label position="floating">{{ __('interface.unit') }}</ion-label>
            <ion-select @ionBlur="form.weight_unit = $event.target.value" v-model="form.weight_unit"
                        :ok-text="__('interface.ok')" :cancel-text="__('interface.cancel')">
              <ion-select-option v-for="(option, key) in weightOptions" :key="key" :value="option.value">
                {{ option.label }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-button expand="full" class="ion-margin-vertical" @click="actionSave">{{ __('interface.save') }}</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonCol, IonPage, IonRow, loadingController} from "@ionic/vue";
import AuthService from "../AuthService";
import {API} from "@/utils/apiWrapper";
import {profileHelper} from '@/utils/profileHelper'
import {showMessages} from '@/utils/showMessages'
import {showValidationMessages} from '@/utils/showValidationMessages'

export default {
  name: "PersonalInformation",
  mixins: [AuthService, profileHelper],
  components: {IonPage, IonRow, IonCol},
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        date_of_birth: '1993-03-03',
        sex: 'female',
        height: 0,
        height_unit: 'cm',
        weight: 0,
        weight_unit: 'kg',
        profile_photo: null,
        resting_heart_rate: 0,
        max_heart_rate: 0
      },
      loading: null,
      loadingState: true
    }
  },
  methods: {
    async actionSave() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      const body = { ...this.form }
      body.height = this.calcHeightForSave(this.form.height_unit)

      await API.put('TheSpottApi', '/me', {body: body}).then(async response => {
        this.$store.commit('setUser', response.data)
        await loading.dismiss();
        await showMessages(response.messages);
        this.$tracking.event({
          eventName: 'update-profile',
          category: 'profile',
          event: this.form
        });
      }).catch(async (e) => {
        await loading.dismiss();
        await showMessages(e.response.data.messages);
        await showValidationMessages(e.response.data.error);
      })
    }
  },
  async ionViewWillEnter() {
    this.loadingState = true;
    this.loading = await loadingController.create({
      message: this.__('interface.loading'),
    });
    await this.loading.present();
  },
  async ionViewDidEnter() {
    this.form = await this.user();
    this.initHeightConvert(this.form.height_unit)
    this.loadingState = false;
    await this.loading.dismiss();
  },
}
</script>

<style lang="scss" scoped>
  ion-col {
    padding: 0;
  }
</style>